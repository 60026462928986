<template>
    <div class="associate">
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>关联管理</span>
                </div>
            </template>
            <div class="content">
                <el-input v-model="keyword" size="small" placeholder="请输入关联名称/产品编号搜索" style="width:250px;margin-right:20px;"></el-input>
                <el-select v-model="status" size="small" clearable style="margin-right:20px;">
                    <el-option value="1" label="启用"></el-option>
                    <el-option value="2" label="停用"></el-option>
                </el-select>
                <el-button size="small" type="primary" @click="init">查询</el-button>
                <el-button size="small" type="success" @click="Add">新建关联</el-button>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                          v-loading="load"
                          element-loading-spinner="el-icon-loading"
                          element-loading-text="加载中..."
                          size="small">
                    <el-table-column label="关联名称" prop="AssociateName"></el-table-column>
                    <el-table-column label="商品数量" prop="Count">
                        <template #default="scope">
                            <el-button @click="CheckAssoicate(scope.row.ID)" type="primary">{{ scope.row.Count }}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态">
                        <template #default="scope">
                            <el-switch v-model="scope.row.Status" active-value="1" inactive-value="2" @change="ChangeStatus(scope.row.ID, scope.row.Status)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间">
                        <template #default="scope">
                            <span>{{ formatDate(scope.row.AddDTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="更新时间">
                        <template #default="scope">
                            <span>{{ formatDate(scope.row.UpdateDTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建人" prop="OperatorName">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-button @click="Edit(scope.row.ID)" type="primary">编辑</el-button>
                            <el-button v-if="scope.row.Status == 2" @click="Del(scope.row.ID)" type="primary">删除</el-button>
                            <el-button @click="CheckAssoicate(scope.row.ID)" type="primary">查看</el-button>
                            <el-button @click="Log(scope.row.ID)" type="primary">日志</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"
                               :current-page="curpage"
                               :page-size="pagesize"
                               :page-sizes="[10, 20, 30, 50, 100]"
                               layout="sizes,total, prev, pager, next, jumper"
                               :total="totalcount"
                               :hide-on-single-page="false"
                               style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="asspdt" title="关联产品">
        <el-table :data="associatepdt" border style="width: 100%; margin: 1em 0" size="small">
            <el-table-column label="商品主图" prop="PdtThumb" width="80px" align="center">
                <template #default="scope">
                    <el-image :src="scope.row.PdtThumb"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="商品编号/id" align="center" width="150px">
                <template #default="scope">
                    <span>{{ scope.row.PdtCode }}:{{ scope.row.ID }}</span>
                </template>
            </el-table-column>
            <el-table-column label="商品名称" prop="PdtName" align="center"></el-table-column>
            <el-table-column label="所属商户" prop="Merchant" width="200px" align="center"></el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>
import constant from "@/constant"
export default {
    data() {
        return {
            asspdt: false,
            status: '',
            keyword: '',
            tabledata: [],
            curpage: 1,
            totalcount: 0,
            pagesize: 10,
            associatepdt: [],
        }
    },
    methods: {
        Log(id) {
            this.axios.get(constant.asslog, {
                headers: {
                    "content-type": "applicaton/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
            });
        },
        Edit(id) {
            this.$router.push({ path: "/addassociate", query: { id: id } });
        },
        Add() {
            this.$router.push({ path: "/addassociate" });
        },
        Del(id) {
            this.axios.get(constant.delass, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data == "OK") {
                    this.$message.success("操作成功");
                    this.$router.go(0);
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        CheckAssoicate(id) {
            this.axios.get(constant.associatepdt, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                this.associatepdt = response.data;
                this.asspdt = true;
            });
        },
        ChangeStatus(id, status) {
            this.axios.get(constant.changeassstatus, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    id: id,
                    status: status
                }
            }).then((response) => {
                console.log(response.data);
            });
        },
        formatDate(date, type) {
            let dtime = new Date(date * 1000);
            let year = dtime.getFullYear();
            let month = ("0" + (dtime.getMonth() + 1)).slice(-2);
            let day = ("0" + dtime.getDate()).slice(-2);
            if (type == 2) {
                let hour = ("0" + dtime.getHours()).slice(-2);
                let minute = ("0" + dtime.getMinutes()).slice(-2);
                let second = ("0" + dtime.getSeconds()).slice(-2);
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
            return year + "-" + month + "-" + day;
        },
        init() {
            this.axios.get(constant.getasslist, {
                headers: {
                    "content-type": "application/json"
                },
                params: {
                    keyword: this.keyword,
                    status: this.status,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val
            this.init();
        },
    },
    created: function () {
        this.init();
    }
}
</script>
<style scoped></style>